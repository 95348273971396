<div class="dropdown">
  <ul class="accordion">
    <div class="header-div-list-description">
      <i class="icon i-close-circle left" style="color: black"></i>
      <h6 class="center">
        {{ 'SERVICES.SERVICE_LIST' | translate }}
      </h6>
    </div>

    <div class="header-div" *ngIf="!isCustomizedServices && customerServicesToDisplay.length !== 0">
      <h6 class="center">
        {{ 'DIAGNOSIS.FORM.CUSTOMER_SERVICES' | translate }}
      </h6>
    </div>

    <span *ngIf="!isCustomizedServices">
      <li [ngClass]="{ 'is-open': this.openedCustomerServices.has(j) }"
        *ngFor="let customerService of customerServicesToDisplay; let j = index">
        <div class="accordion__title center" (click)="toggleServiceDescription(j, this.openedCustomerServices)">
          {{ this.customerService.name }}
        </div>
        <div class="accordion__content">
          <div class="row">
            {{
            this.getTransaltedDescription(this.customerService.serviceDescriptions) !== ''
            ? this.getTransaltedDescription(this.customerService.serviceDescriptions)
            : ('SERVICES.MISSING_DESCRIPTION' | translate)
            }}
          </div>
        </div>
      </li>
    </span>

    <div class="header-div" *ngIf="!isCustomizedServices && servicesToDisplay.length !== 0">
      <h6 class="center">
        {{ 'DIAGNOSIS.FORM.CORE_SERVICES' | translate }}
      </h6>
    </div>
    <span *ngIf="!isCustomizedServices">
      <li [ngClass]="{ 'is-open': this.openedCoreServices.has(i) }"
        *ngFor="let coreService of servicesToDisplay; let i = index">
        <div class="accordion__title center" (click)="toggleServiceDescription(i, this.openedCoreServices)">
          {{ this.coreService.name }}
        </div>
        <div class="accordion__content">
          <div class="row">
            {{
            this.getTransaltedDescription(this.coreService.serviceDescriptions) !== ''
            ? this.getTransaltedDescription(this.coreService.serviceDescriptions)
            : ('SERVICES.MISSING_DESCRIPTION' | translate)
            }}
          </div>
        </div>
      </li>
    </span>

    <div class="header-div" *ngIf="isCustomizedServices && customizedServicesToDisplay.length !== 0">
      <h6 class="center">
        {{ 'DIAGNOSIS.FORM.CUSTOMIZED_SERVICES' | translate }}
      </h6>
    </div>

    <span *ngIf="isCustomizedServices">
      <li [ngClass]="{ 'is-open': this.openedCustomizedServices.has(z) }"
        *ngFor="let customizedService of customizedServicesToDisplay; let z = index">
        <div class="accordion__title center" (click)="toggleServiceDescription(z, this.openedCustomizedServices)">
          {{ this.customizedService.name }}
        </div>
        <div class="accordion__content">
          <div class="row">
            {{
            this.getTransaltedDescription(this.customizedService.serviceDescriptions) !== ''
            ? this.getTransaltedDescription(this.customizedService.serviceDescriptions)
            : ('SERVICES.MISSING_DESCRIPTION' | translate)
            }}
          </div>
        </div>
      </li>
    </span>
  </ul>
</div>