<button
  class="additional-parameter-modal-btn btn btn--tertiary flex--align-items-center mar-small"
  title="{{ 'DIAGNOSIS.ADDITIONAL_PARAMETERS.TOOLTIP_DESCRIPTION' | translate }}"
  *ngIf="paramsAvailable()"
  (click)="open()"
>
  <i class="icon i-information"></i>
</button>
<div *ngIf="opened" class="modal">
  <div class="modal__wrapper">
    <h1 class="modal__title">
      {{ 'DIAGNOSIS.ADDITIONAL_PARAMETERS.TITLE' | translate }}
    </h1>
    <div class="modal__close" (click)="close()"></div>
    <div class="modal__content pad-vertical-medium">
      <div>
        <div class="additionalParams table" *ngIf="this.diagnosis.modId === 'WAP'">
          <table
            class="table"
            id="additionalParametersTable"
            name="additionalParametersTable"
            *ngIf="
              this.iterableAdditionalParameters && this.iterableAdditionalParameters.length > 0
            "
          >
            <thead>
              <tr>
                <th>
                  <small>{{ webAppHeading | translate }}</small>
                </th>
                <th>
                  <small>{{ statusHeading | translate }}</small>
                </th>
                <th>
                  <small>{{ versionHeading | translate }}</small>
                </th>
                <th>
                  <small>{{ errorCodeHeading | translate }}</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let param of this.iterableAdditionalParameters">
                <td title="{{ param.name }}">
                  <small>{{ param.name }}</small>
                </td>
                <td title="{{ param.status }}">
                  <small>{{ param.status }}</small>
                </td>
                <td title="{{ param.version }}">
                  <small>{{ param.version }}</small>
                </td>
                <td title="{{ param.installationErrorCode }}">
                  <small>{{ param.installationErrorCode }}</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="additional-parameter-box"
          *ngFor="let entry of this.diagnosisAdditionalParameters | keyvalue"
        >
          <label class="additional-parameter-key">{{ entry.value.name }}:</label>
          <p class="additional-parameter-value">{{ entry.value.value }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
