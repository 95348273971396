import {Component, numberAttribute, OnInit, Output} from '@angular/core';
import {
  NewsletterLocalizedResponse
} from 'src/app/shared/interfaces/newsletter.interface';
import { env } from 'src/app/environment';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user-service.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  newsletters: NewsletterLocalizedResponse[] = [];
  selectedNewsletter: NewsletterLocalizedResponse;
  stayOpen: boolean;
  newsletterIndex: number;
  selectedLanguage: string;
  dateFormat = env.dateFormat;

  constructor(
    private newsletterService: NewsletterService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.stayOpen = true;
  }

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe(() => {
        this.selectedLanguage = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe(({ lang }) => {
          this.selectedLanguage = lang;
          this.refreshNewsletters();
        });
        this.refreshNewsletters();

        // Refresh newsletters every 10 minutes
        setInterval(() => this.refreshNewsletters(), 1000 * 60 * 10);
      });
  }

  toggleOpen() {
    if (this.stayOpen === false) {
      this.stayOpen = true;
    } else {
      this.stayOpen = false;
    }
  }

  refreshNewsletters() {
    const tokenExpiration = parseInt(sessionStorage.getItem('expires_at'));
    if (tokenExpiration <= Date.now()) {
      console.error('Token has expired, newsletter will not be refreshed!');
      return;
    }

    this.newsletterIndex = 0;
    this.newsletterService.getNewsletters(this.selectedLanguage).subscribe((newsletters) => {
      this.newsletters = newsletters;
      this.newsletters.sort((a, b) => Date.parse(a.expirationDate) - Date.parse(b.expirationDate));
      if (this.newsletters !== undefined || this.newsletters.length !== 0) {
        this.selectedNewsletter = this.newsletters[this.newsletterIndex];
      }
    });
  }

  onClickDot(index: number) {
    this.newsletterIndex = index;
    this.selectedNewsletter = this.newsletters[this.newsletterIndex];
  }
}
