import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants, Role } from 'src/app/shared/constants';
import { User } from '../../shared/models/user.model';
import {delay, filter, retryWhen, take, tap} from 'rxjs/operators';
import { UserService } from '../../shared/services/user-service.service';
import { env } from '../../environment';
import { RolesService } from '../../shared/services/roles-service.service';
import { RoleChange } from '../../shared/models/mapped-roles.model';
import { DiagnosisLanguageService } from '../../shared/services/diagnosis-language.service';
import {NewsletterService} from "../../shared/services/newsletter.service";
import {NewsletterLocalizedResponse} from "../../shared/interfaces/newsletter.interface";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public user: User;
  public ADMIN_COMPETENCE_AND_TESTER = [Role.ADMIN, Role.COMPETENCE_CENTER, Role.TESTER];
  public ALL_ROLES = Constants.ALL_ROLES;
  public regions: Array<string>;
  public selectedRole: string;
  public selectedBrand: string;
  public Role = Role;
  public numberOfNewsletters: number;
  public newsletters: NewsletterLocalizedResponse[] = [];

  constructor(
    private userService: UserService,
    public router: Router,
    public diagnosisLanguageService: DiagnosisLanguageService,
    public rolesService: RolesService,
    private newsletterService: NewsletterService,
  ) {
    this.regions = env.regions;
  }

  ngOnInit() {
    this.userService.login();
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe((x) => {
        this.user = x;
      });
    this.rolesService.onRoleChange.subscribe((event: RoleChange) => {
      this.selectedRole = event.role;
      this.selectedBrand = event.brand;
    });

    this.newsletterService.getNewsletters(this.diagnosisLanguageService.pickedDiagnosisLanguage).pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(err => console.error('Error occurred, retrying...', err)),
          delay(1000),
        )
      )
    ).subscribe(
      (newsletters) => {
        this.numberOfNewsletters = newsletters.length;
      }
    );

    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.setAttribute('data-theme', 'dark');
      const darkModeSwitch = document.getElementById('dark_mode_switch') as HTMLInputElement;
      darkModeSwitch.checked = true;
    }
  }

  isLoggedIn(): User {
    return this.user;
  }

  logout() {
    this.userService.logout();
  }

  changeLanguage(lang: string) {
    this.diagnosisLanguageService.pickedDiagnosisLanguage = lang;
  }

  switchDarkmode(event: any) {
    if (event.srcElement.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
  }
}
