import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import { merge, defer, of } from 'rxjs';
import { FormArray as FormArray$1, FormControl as FormControl$1, FormGroup as FormGroup$1 } from '@angular/forms';
function mapToOpposite() {
  return source$ => {
    return source$.pipe(map(b => !b));
  };
}
function setEnabled(control, enabled = true) {
  if (enabled) {
    control.enable();
  } else {
    control.disable();
  }
}
function setDisabled(control, disabled = true) {
  setEnabled(control, !disabled);
}
function formControlPristine$(formControl) {
  return merge(defer(() => of(formControl.valid)), formControl.valueChanges.pipe(map(() => formControl.pristine), distinctUntilChanged()));
}
function abstractControlDirty$(formControl) {
  return formControlPristine$(formControl).pipe(mapToOpposite());
}
function formControlEnabled$(formControl) {
  return merge(defer(() => of(formControl.enabled)), formControl.statusChanges.pipe(map(() => formControl.enabled), distinctUntilChanged()));
}
function abstractControlDisabled$(formControl) {
  return formControlEnabled$(formControl).pipe(mapToOpposite());
}
function formControlErrors$(formControl) {
  return merge(defer(() => of(formControl.errors)), formControl.valueChanges.pipe(map(() => formControl.errors), distinctUntilChanged()));
}
function formControlValid$(formControl) {
  return merge(defer(() => of(formControl.valid)), formControl.statusChanges.pipe(map(() => formControl.valid), distinctUntilChanged()));
}
function formControlInvalid$(formControl) {
  return formControlValid$(formControl).pipe(mapToOpposite());
}
function formControlStatus$(formControl) {
  return merge(defer(() => of(formControl.status)), formControl.statusChanges.pipe(map(() => formControl.status)));
}
function formControlValue$(formControl) {
  return merge(defer(() => of(formControl.value)), formControl.valueChanges);
}

/**
 * A default ControlValueAccessor implementation
 */
class DefaultControlValueAccessor {
  writeValue(value) {
    this.control.setValue(value, {
      emitEvent: false
    });
  }
  registerOnChange(onChange) {
    this.control.valueChanges.subscribe(onChange);
  }
  registerOnTouched() {}
  setDisabledState(disabled) {
    disabled ? this.control.disable({
      emitEvent: false
    }) : this.control.enable({
      emitEvent: false
    });
  }
  validate() {
    return this.control.invalid ? {
      invalid: true
    } : null;
  }
}

/**
 * Provides a type safe FormControl class which accepts a generic type T.
 */
class FormArray extends FormArray$1 {
  constructor(controls, validatorOrOpts, asyncValidator) {
    super(controls, validatorOrOpts, asyncValidator);
    this.controls = controls;
  }
  get validator() {
    return super.validator;
  }
  set validator(value) {
    super.validator = value;
  }
  get asyncValidator() {
    return super.asyncValidator;
  }
  set asyncValidator(value) {
    super.asyncValidator = value;
  }
  setValidators(newValidator) {
    super.setValidators(newValidator);
  }
  setAsyncValidators(newValidator) {
    super.setAsyncValidators(newValidator);
  }
  at(index) {
    return super.at(index);
  }
  push(control) {
    return super.push(control);
  }
  insert(index, control) {
    return super.insert(index, control);
  }
  setControl(index, control) {
    return super.setControl(index, control);
  }
  setValue(value, options) {
    return super.setValue(value, options);
  }
  patchValue(value, options) {
    return super.patchValue(value, options);
  }
  reset(value, options) {
    return super.reset(value, options);
  }
  getRawValue() {
    return super.getRawValue();
  }
  get value$() {
    return formControlValue$(this);
  }
  get errors$() {
    return formControlErrors$(this);
  }
  get enabled$() {
    return formControlEnabled$(this);
  }
  get pristine$() {
    return formControlPristine$(this);
  }
  get valid$() {
    return formControlValid$(this);
  }
  get status$() {
    return formControlStatus$(this);
  }
  get disabled$() {
    return abstractControlDisabled$(this);
  }
  get dirty$() {
    return abstractControlDirty$(this);
  }
  get invalid$() {
    return formControlInvalid$(this);
  }
  get validValue$() {
    return this.value$.pipe(filter(() => this.valid));
  }
  setEnabled(enabled = true) {
    setEnabled(this, enabled);
  }
  setDisabled(disabled = true) {
    setDisabled(this, disabled);
  }
}

/**
 * Provides a type safe FormControl class which accepts a generic type T.
 */
class FormControl extends FormControl$1 {
  constructor(formState, validatorOrOpts, asyncValidator) {
    super(formState, validatorOrOpts, asyncValidator);
  }
  get validator() {
    return super.validator;
  }
  set validator(value) {
    super.validator = value;
  }
  get asyncValidator() {
    return super.asyncValidator;
  }
  set asyncValidator(value) {
    super.asyncValidator = value;
  }
  setValidators(newValidator) {
    super.setValidators(newValidator);
  }
  setAsyncValidators(newValidator) {
    super.setAsyncValidators(newValidator);
  }
  setValue(value, options) {
    super.setValue(value, options);
  }
  patchValue(value, options) {
    super.patchValue(value, options);
  }
  reset(formState, options) {
    super.reset(formState, options);
  }
  get value$() {
    return formControlValue$(this);
  }
  get errors$() {
    return formControlErrors$(this);
  }
  get enabled$() {
    return formControlEnabled$(this);
  }
  get pristine$() {
    return formControlPristine$(this);
  }
  get valid$() {
    return formControlValid$(this);
  }
  get status$() {
    return formControlStatus$(this);
  }
  get disabled$() {
    return abstractControlDisabled$(this);
  }
  get dirty$() {
    return abstractControlDirty$(this);
  }
  get invalid$() {
    return formControlInvalid$(this);
  }
  get validValue$() {
    return this.value$.pipe(filter(() => this.valid));
  }
  setEnabled(enabled = true) {
    setEnabled(this, enabled);
  }
  setDisabled(disabled = true) {
    setDisabled(this, disabled);
  }
}

/**
 * A type-safe FormGroup class which accepts a generic type T.
 */
class FormGroup extends FormGroup$1 {
  constructor(controls, validatorOrOpts, asyncValidator) {
    super(controls, validatorOrOpts, asyncValidator);
    this.controls = controls;
  }
  get validator() {
    return super.validator;
  }
  set validator(value) {
    super.validator = value;
  }
  get asyncValidator() {
    return super.asyncValidator;
  }
  set asyncValidator(value) {
    super.asyncValidator = value;
  }
  setValidators(newValidator) {
    super.setValidators(newValidator);
  }
  setAsyncValidators(newValidator) {
    super.setAsyncValidators(newValidator);
  }
  registerControl(name, control) {
    return super.registerControl(name, control);
  }
  addControl(name, control) {
    return super.addControl(name, control);
  }
  removeControl(name) {
    return super.removeControl(name);
  }
  setControl(name, control) {
    return super.setControl(name, control);
  }
  contains(controlName) {
    return super.contains(controlName);
  }
  reset(value, options) {
    return super.reset(value, options);
  }
  getRawValue() {
    return super.getRawValue();
  }
  setValue(value, options) {
    return super.setValue(value, options);
  }
  patchValue(value, options) {
    return super.patchValue(value, options);
  }
  get value$() {
    return formControlValue$(this);
  }
  get errors$() {
    return formControlErrors$(this);
  }
  get enabled$() {
    return formControlEnabled$(this);
  }
  get pristine$() {
    return formControlPristine$(this);
  }
  get valid$() {
    return formControlValid$(this);
  }
  get status$() {
    return formControlStatus$(this);
  }
  get disabled$() {
    return abstractControlDisabled$(this);
  }
  get dirty$() {
    return abstractControlDirty$(this);
  }
  get invalid$() {
    return formControlInvalid$(this);
  }
  get validValue$() {
    return this.value$.pipe(filter(() => this.valid));
  }
  setEnabled(enabled = true) {
    setEnabled(this, enabled);
  }
  setDisabled(disabled = true) {
    setDisabled(this, disabled);
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { DefaultControlValueAccessor, FormArray, FormControl, FormGroup, abstractControlDirty$, abstractControlDisabled$, formControlEnabled$, formControlErrors$, formControlInvalid$, formControlPristine$, formControlStatus$, formControlValid$, formControlValue$, mapToOpposite as ɵa };
