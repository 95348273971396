import {DiagnosisForm} from "../models/diagnosis.model";
import {FormGroup, ValidatorFn} from "ngx-typesafe-forms";

export const isValidRequestFrom = <T extends DiagnosisForm>(
    form: FormGroup<T>
): ValidatorFn<T> => (control) => {
    let error;
    if (form.controls.to.value < form.controls.from.value) {
        error = { invalidRange: { value: form.controls.to.value } };
    }
    return error;
};
