<div class="taskbar taskbar--top">
  <img class="taskbar__logo" alt="logo" src="/assets/images/vw-logo-neu.svg" />
  <div class="taskbar__header">
    <h1>
      <a href="#">{{ 'HEADER.TITLE' | translate }}</a>
    </h1>
  </div>
  <div class="iconbar">
    <div class="horizontal-margins-small">
      <input
        id="dark_mode_switch"
        name="dark_mode_switch"
        type="checkbox"
        (change)="switchDarkmode($event)"
        autocomplete="off"
      />
      <label class="is-switch" for="dark_mode_switch">Darkmode</label>
      <details class="dropdown dropdown--left">
        <summary class="dropdown__indicator language-selector-dropdown">
          <b class="pad-small">
            {{ diagnosisLanguageService.pickedDiagnosisLanguage.toUpperCase() }}
          </b>
          <i class="icon i-dropdown-closed"></i>
        </summary>
        <div class="dropdown__content">
          <ul class="list list--no-link-icon" name="languageSelector" id="languageSelector">
            <li *ngFor="let language of diagnosisLanguageService.availableDiagnosisLanguages">
              <a (click)="changeLanguage(language)">{{ language.toUpperCase() }}</a>
            </li>
          </ul>
        </div>
      </details>
    </div>
  </div>

  <details class="dropdown dropdown--left">
    <summary class="dropdown__indicator">
      <b *ngIf="user" id="username" class="pad-large"> {{ user.name }} </b>
      <div class="taskbar__picture avatar">
        <app-form-aware-avatar></app-form-aware-avatar>
      </div>
      <i class="icon i-dropdown-closed"></i>
    </summary>
    <div class="dropdown__content">
      <ul class="pad-horizontal-medium list list--no-link-icon">
        <li>
          <a *ngIf="isLoggedIn()" class="pad-small" routerLink="/profile">{{
            'HEADER.TASKBAR.PROFILE' | translate
          }}</a>
        </li>
        <li>
          <a *ngIf="isLoggedIn()" class="pad-small" href="javascript:void(0)" (click)="logout()">{{
            'HEADER.TASKBAR.LOGOUT' | translate
          }}</a>
        </li>
        <li *ngIf="!isLoggedIn()">
          <a href="/login" class="pad-small">{{ 'HEADER.TASKBAR.LOGIN' | translate }}</a>
        </li>
      </ul>
    </div>
  </details>
</div>

<nav class="navbar">
  <ul>
    <li [ngClass]="{ 'is-active': this.router.isActive('vin-diagnosis', false) }">
      <a routerLink="/vin-diagnosis">{{ 'HEADER.NAVIGATION.VIN_DIAGNOSIS' | translate }}</a>
    </li>
    <li
      [ngClass]="{
        'is-active': this.router.isActive('traceid-diagnosis', false)
      }"
      *ngIf="rolesService.checkIfUserHasAnyOfPermissions(ADMIN_COMPETENCE_AND_TESTER)"
    >
      <a routerLink="/traceid-diagnosis">{{ 'HEADER.NAVIGATION.TRACEID_DIAGNOSIS' | translate }}</a>
    </li>
    <li
      [ngClass]="{
        'is-active': this.router.isActive('customized-services-diagnosis', false)
      }"
      *ngIf="rolesService.checkIfUserHasAccessToCustomizedServices()"
    >
      <a routerLink="/customized-services-diagnosis">{{
        'HEADER.NAVIGATION.CUSTOMIZED_SERVICES_DIAGNOSIS' | translate
      }}</a>
    </li>

    <li class="notification-newsletter"
        *ngIf="numberOfNewsletters > 0"
        title="{{'HEADER.NAVIGATION.NEWSLETTER_NOTIFICATION_ALERT' | translate }}"
    >
      <a  href="#newsletter">
        <img
          src="/assets/dist/vwag/svg/sets/basic/notification.svg"
          class="newsletter-logo-svg"
          alt="newsletter-logo"
        >
        <div class="tag tag--secondary" name="service-number">
          {{numberOfNewsletters}}
        </div>
      </a>
    </li>
  </ul>
</nav>
