export enum Role {
  ADMIN = 'ADMIN',
  CUSTOMER_CARE = 'CUSTOMERCARE',
  TESTER = 'TESTER',
  COMPETENCE_CENTER = 'COMPETENCECENTER',
  INSPECTOR = 'INSPECTOR',
  WORKSHOP = 'WORKSHOP',
  CUSTOMER = 'CUSTOMER'
}

export class Constants {
  public static diagnosisServiceBaseURL = '/api/diagnosis';
  public static configServiceBaseURL = '/api/config';

  public static CORE_SERVICES_URL = `${Constants.diagnosisServiceBaseURL}/services`;
  public static CUSTOMIZED_SERVICES_URL = `${Constants.diagnosisServiceBaseURL}/services/customized`;
  public static FAVOURITE_SERVICES_URL = `${Constants.configServiceBaseURL}/services/favourites`;
  public static DIAGNOSIS_URL = `${Constants.diagnosisServiceBaseURL}/v2/diagnose`;
  public static CUSTOMIZED_SERVICE_DIAGNOSIS_URL = `${Constants.diagnosisServiceBaseURL}/v2/diagnose/customized`;
  public static CHART_URL = `${Constants.diagnosisServiceBaseURL}/chart`;
  public static CHART_DIAGNOSIS_RESPONSE_TYPES = `${Constants.diagnosisServiceBaseURL}/chart/failure-types`;
  public static FEEDBACK_URL = `${Constants.diagnosisServiceBaseURL}/feedback`;
  public static NEWSLETTERS_URL = `${Constants.configServiceBaseURL}/newsletters`;

  public static ALL_ROLES: Role[] = Object.keys(Role).map((role) => Role[role]);

  public static userIdPattern = new RegExp(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
  public static emailPattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  public static BRAND_PRODUCTION = 'PRODUCTION';

  public static DEFAULT_PAGE_SIZE = 50;
  public static DEFAULT_OFFSET = 0;

  public static allowedVins = new Map<string, string[]>([
    ['VWAG', ['WVW', 'HZW', 'BVW', 'BVN', 'WVG', 'WV1', 'WV2', 'WV3', 'WV4']],
    ['SEAT', ['VSS', 'BSE']],
    ['TEST', ['VSS', 'BSE', 'WVW', 'HZW', 'BVW', 'BVN', 'WVG', 'WV1', 'WV2']]
  ]);

  public static BAR_OFFSET = 30;
}
