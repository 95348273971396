{
  "name": "@e2ed/diagnosis-tool",
  "version": "1.12.72",
  "engines": {
    "node": "20.14.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-prod": "ng build -c production",
    "test": "ng test",
    "test-headless-chrome": "ng test --watch=false --code-coverage --browsers=CustomChromeHeadless",
    "test-headless-chromium": "ng test --watch=false --code-coverage --browsers=CustomChromiumHeadless",
    "lint": "ng lint",
    "sonar": "sonar-scanner",
    "owasp": "owasp-dependency-check --project \"e2ed-diagnosis-tool\" --scan \"package-lock.json\" --exclude \"dependency-check-bin\" --out \"owasp\" --format JSON --proxyserver $proxy_host --proxyport $proxy_port --proxyuser $proxy_user --proxypass $proxy_pass --artifactoryUseProxy true --nonProxyHosts \"$NO_PROXY\"",
    "prepare": "husky install"
  },
  "files": [
    "dist/**",
    "manifest.yml",
    "manifest-cn.yml",
    "*fosslist.xlsx"
  ],
  "publishConfig": {
    "registry": "https://devstack.vwgroup.com/artifactory/api/npm/eted-fe-releases/"
  },
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@ngx-translate/core": "~15.0.0",
    "@ngx-translate/http-loader": "~8.0.0",
    "angular-oauth2-oidc": "^19.0.0",
    "apexcharts": "3.33.0",
    "dayjs": "^1.11.13",
    "karma-coverage": "^2.2.0",
    "marked": "^15.0.7",
    "moment-timezone": "^0.5.46",
    "ng-apexcharts": "^1.8.0",
    "ng-pick-datetime-ex": "^14.0.0",
    "ngx-markdown": "^15.1.0",
    "ngx-mask": "15.0.0",
    "ngx-typesafe-forms": "~1.2.3",
    "project-version": "^2.0.0",
    "rxjs": "^7.8.2",
    "sonarqube-scanner": "^3.0.1",
    "swagger-ui": "^4.19.0",
    "tslib": "^2.4.0",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.14",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^18.2.14",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/language-service": "^18.2.13",
    "@group-ui/group-ui-angular": "https://groupui.vwapps.run/specific/11.1.1/packages/group-ui-angular.tgz",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "^2.0.10",
    "@types/node": "~14.10.3",
    "@types/swagger-ui": "^3.52.0",
    "@typescript-eslint/eslint-plugin": "^7.16.1",
    "@typescript-eslint/parser": "^7.16.1",
    "eslint": "^8.12.0",
    "husky": "^8.0.0",
    "jasmine-core": "~4.1.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.3.20",
    "karma-chrome-launcher": "^3.1.1",
    "karma-jasmine": "^4.0.2",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-sonarqube-unit-reporter": "0.0.23",
    "license-checker": "~25.0.1",
    "owasp-dependency-check": "0.0.21",
    "ts-node": "~9.0.0",
    "tslint-config-prettier": "~1.18.0",
    "typescript": "^5.2.2"
  }
}
