<ul class="accordion" name="newslettterAccordion" *ngIf="newsletters.length > 0" id="newsletter">
  <li [ngClass]="{ 'is-open': stayOpen === true }" class="is-open">
    <div class="accordion__title" (click)="toggleOpen()" name="newslettterAccordion-Title"></div>
    <div class="accordion__content bg-primary100">
      <div class="card bg-primary100">
        <div class="card__content">
          <div name="newsletterTitle">
            <textarea class="center h5 text-white" disabled rows="2">{{
              this.selectedNewsletter.title || 'NEWSLETTER-DASHBOARD.EMPTY-CONTENT' | translate
            }}</textarea>
          </div>
          <div class="col sm-8 sm-offset-2" name="newsletterContent">
            <textarea class="text-white" disabled rows="8">{{
              this.selectedNewsletter.content || 'NEWSLETTER-DASHBOARD.EMPTY-CONTENT' | translate
            }}</textarea>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
<div class="center bg-primary100" *ngIf="newsletters.length > 0 && stayOpen">
  <ol class="dots">
    <li
      class="dots__dot"
      id="newsletter-{{ i }}"
      *ngFor="let newsletter of newsletters; let i = index"
      (click)="onClickDot(i)"
      [ngClass]="{ 'dots__dot--active': newsletterIndex === i }"
    ></li>
  </ol>
</div>
