import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { BaseDiagnosisComponent } from 'src/app/components/diagnosis/base-diagnosis/base-diagnosis.component';
import { UserService } from 'src/app/shared/services/user-service.service';
import { NotificationService } from '../../components/notification/notification.service';
import { env } from '../../environment';
import { Constants, Role } from '../../shared/constants';
import { VinRequestForm } from '../../shared/models/diagnosis.model';
import { VinDiagnosisService } from '../../shared/services/diagnosis.service/diagnosis.service';
import { RolesService } from '../../shared/services/roles-service.service';
import { ServicesService } from '../../shared/services/services.service';
import { patternValidator } from '../../shared/validators/pattern-validator.directive';
import { sizeValidator } from '../../shared/validators/size-validator.directive';
import { userIdValidator } from '../../shared/validators/user-validator.directive';
import { vinValidator } from 'src/app/shared/validators/vin-validator.directive';
import { DiagnosisLanguageService } from '../../shared/services/diagnosis-language.service';
import { vinPrefixValidator } from '../../shared/validators/vin-prefix-validator.directive';
import { HttpClient } from '@angular/common/http';
import { userAndVinValidator } from 'src/app/shared/validators/user-and-vin-validator.directive';
import { userOrVinValidator } from '../../shared/validators/vin-or-user-id-validator.directive';

@Component({
  selector: 'app-vin-diagnosis',
  templateUrl: './vin-diagnosis.component.html',
  styleUrls: ['./vin-diagnosis.component.scss']
})
export class VinDiagnosisComponent extends BaseDiagnosisComponent<VinRequestForm> {
  readonly ALL_ROLES: Role[] = Constants.ALL_ROLES;
  public features = env.features;
  public Role = Role;

  public readonly USER_KEY = 'user';
  public readonly VIN_KEY = 'vin';

  constructor(
    diagnosisLanguageService: DiagnosisLanguageService,
    servicesService: ServicesService,
    diagnosisService: VinDiagnosisService,
    notificationService: NotificationService,
    cdr: ChangeDetectorRef,
    userService: UserService,
    rolesService: RolesService,
    http: HttpClient
  ) {
    super(
      diagnosisLanguageService,
      servicesService,
      diagnosisService,
      notificationService,
      cdr,
      userService,
      rolesService,
      http
    );
    // @ts-ignore
    super.diagnoses = null;
  }

  protected extendForm(form: FormGroup<VinRequestForm>) {
    form.addControl(
      this.USER_KEY,
      new FormControl(this.diagnosisService.getFormValue().user, [
        this.features.get('emailSearch')
          ? patternValidator([Constants.emailPattern, Constants.userIdPattern])
          : patternValidator([Constants.userIdPattern])
      ])
    );

    form.addControl(
      this.VIN_KEY,
      new FormControl(this.diagnosisService.getFormValue().vin, [sizeValidator(17)])
    );

    form.addValidators([
      userAndVinValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      userOrVinValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      userIdValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      vinValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      vinPrefixValidator()
    ]);
  }
}
