import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule
} from 'ng-pick-datetime-ex';
import { provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { FormAwareAvatarComponent } from './components/avatar/form-aware-avatar.component';
import { DiagnosisTableComponent } from './components/diagnosis-table/diagnosis-table.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationListComponent } from './components/notification/notification.component';
import { UnathorizedAccessComponent } from './components/unauthorized-access/unathorized-access.component';
import { DefaultOAuthInterceptor } from './oauthinterceptor';
import { ContactpersonsComponent } from './pages/contactpersons/contactpersons.component';
import { DsgvoComponent } from './pages/dsgvo/dsgvo.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { TraceIdDiagnosisComponent } from './pages/traceid-diagnosis/traceid-diagnosis.component';
import { VinDiagnosisComponent } from './pages/vin-diagnosis/vin-diagnosis.component';
import { RolesService } from './shared/services/roles-service.service';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ServiceDescriptionsListComponent } from './components/service-descriptions-list/service-descriptions-list.component';
import { MoreLessWrapperComponent } from './components/more-less-wrapper/more-less-wrapper.component';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import {MatTooltipModule} from '@angular/material/tooltip';
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';
import { SwaggerPageComponent } from './pages/swagger/swagger-page/swagger-page.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import { NumOfHitsChartComponent } from './components/num-of-hits-chart/num-of-hits-chart.component';
import { AdditionalParametersModalComponent } from './components/diagnosis-table/additonal-parameters-modal/additonal-parameters-modal.component';
import { CustomizedServicesDiagnosisComponent } from './pages/customized-services-diagnosis/customized-services-diagnosis.component';
import {NgOptimizedImage} from "@angular/common";

const appRoutes: Routes = [
  {
    path: 'dsgvo',
    component: DsgvoComponent
  },
  {
    path: 'help',
    component: ContactpersonsComponent
  },
  {
    path: 'profile',
    component: ProfilePageComponent
  },
  {
    path: 'traceid-diagnosis',
    component: TraceIdDiagnosisComponent
  },
  {
    path: 'vin-diagnosis',
    component: VinDiagnosisComponent
  },
  {
    path: 'customized-services-diagnosis',
    component: CustomizedServicesDiagnosisComponent
  },
  {
    path: 'swagger',
    component: SwaggerPageComponent
  },
  {
    path: '',
    redirectTo: 'vin-diagnosis',
    pathMatch: 'prefix'
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    VinDiagnosisComponent,
    CustomizedServicesDiagnosisComponent,
    DiagnosisTableComponent,
    UnathorizedAccessComponent,
    DsgvoComponent,
    ContactpersonsComponent,
    TraceIdDiagnosisComponent,
    FormErrorComponent,
    NotificationListComponent,
    ProfilePageComponent,
    AvatarComponent,
    FormAwareAvatarComponent,
    FeedbackModalComponent,
    NewsletterComponent,
    ServiceDescriptionsListComponent,
    MoreLessWrapperComponent,
    SearchDropdownComponent,
    NumOfHitsChartComponent,
    SwaggerPageComponent,
    AdditionalParametersModalComponent
  ],
  imports: [
    BrowserModule,
    NgApexchartsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    FormsModule,
    RouterModule.forRoot(appRoutes, {
        scrollPositionRestoration: 'top'
    }),
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    MatInputModule,
    MatSidenavModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: true, // enable marked built-in html sanitizer
          smartLists: true,
          smartypants: false
        }
    }}),
    MatTooltipModule,
    NgOptimizedImage
  ],
  providers: [
    HttpClientModule,
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true
    },
    {
      provide: OWL_DATE_TIME_LOCALE,
      useValue: 'DE-de'
    },
    RolesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
